
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.news {
  &__pagination {
    margin-top: 20px;
  }
  &__actions {
    display: flex;
    flex-direction: row;
    &-item {
      cursor: pointer;
      margin: 0 0 0 10px;
      &:first-child {
        margin: 0;
      }
    }
  }
  &__main-img-wrapper {
    position: relative;

    .esmp-button {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  &__main-img {
    max-width: 200px;
  }
  &__error {
    padding: 0 $base-gutter;
    color: $error-color;
  }
}

.form-item {
  margin: 20px 0 0 0;
  &:first-child {
    margin: 0;
  }
}
